import { render, staticRenderFns } from "./Presidente-Lucena.vue?vue&type=template&id=336b2aac&scoped=true&"
import script from "./Presidente-Lucena.vue?vue&type=script&lang=js&"
export * from "./Presidente-Lucena.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "336b2aac",
  null
  
)

export default component.exports